
// CONTENT.JS
//
// This File contains the contents of the website!
// Changes in this file only take effect, after: npm run build
//
// The website content is specified as a javascript object literal below.
// The "content"-fields, below can be an object or an array of objects. Content objects can be nested.
//
// Hint: Use ` ` for multi-line strings. (This is what requires the build step - as it is not supported by all browsers)
//
// Supported Objects in content fields:
//
// // Simple HTML:
// 	{
//		type: "html"
//		value: "<p>some html<p>"
// 	}
// // Expandable: Allows to show/hide content by clicking on title
// 	{
// 		type: "expandable",
// 		title: "The Title",
// 		content: []
// 	}
// // Cards: Allows to display content in cards
// 	{
// 		type: "cards", // use "cards-light" for dark background
// 		cards:
// 		[
// 			{
// 				card: "1",
// 				content: []
// 			},
// 			{
// 				card: "2",
// 				content: []
// 			},
// 		]
// 	}
// // Table:
// 	{
// 		type: "table",
// 		widths: ['4em','auto','auto','auto'], // column widths
// 		// rowStyle: ["font-weight: bold;font-size: 1.2em", "font-weight: bold; background-color: #2B628D; color: white","font-size: 0.9em;","font-size: 0.9em;","font-size: 0.9em;","font-size: 0.9em;","font-size: 0.9em;"],
// 		// colStyle: ["font-weight: bold"],
// 		// cellStyle: "",

// 		// rowClass: [],
// 		// colClass: [],
// 		// cellClass: "",

// 		table: [
// 			["cell11","cell12","cell13","cell14"], // row 1
// 			["cell21","cell22","cell23","cell24"], // row 2
// 		]
// }
// Components:
// 	{
// 		type: "component:co-sponsors" / "component:sponsors" / "component:speakers" / "component:organizers"
// 	}


// Styles AND Classes:
// Except for Components (i.e., type: "component:..."). All content objects support fields "class" & "style" to add specific classes and styles to the contents.

// Timing:
// Every content object or property (simple properties can be made objects as {value: string})
// can have "from" and / or "till" properties that specify when they are to be displayed.
// If there are multiple choices depending on the date. Arrays of {value: x, from:y, till:z} are possible. The first match will be displayed.
// Time Format is: 2020-09-29T14:23:00 // all time must be specified in CEST = UTC+2
// Examples:
// header:{
//	 message: [{value: "Register Now", till:"2020-09-29T08:00"},{value: "Ongoing",from:"2020-09-29T08:00"}]
// }
//

// Icons: https://fontawesome.com/v5.3.1/icons?d=gallery

const liveStreamUrl = "https://events.zoom.us/ev/AiVIUYJB3177JMP1Wtt80E0HSdInHYuZgX3IMHbbTRNlss2hQbf_~AggLXsr32QYFjq8BlYLZ5I06Dg";
const liveStreamProvider = "Zoom Events";

const cfp = `<p>To be published soon.</p>`

export var content = {
	copyrightText: "© Barkhausen Institut - ",

	menu:[

		{text: "Home", target: "page-top"},
		{text: "Speakers", target: "speakers"},
		{text: "Program", target: "program"},
		{text: "Venue", target: "venue"},
		{text: "Registration", target: "registration" },
		//{text: "Tutorials", target: "cft" },
		{text: "CfP", target: "cfp" },
		//{text: "Deadlines", target: "deadlines" },
		{text: "Committee", target: "committee"},

	],
	header: {
		darkCover: true,
		preTitle: '<span style="color: white">5th <strong>IEEE</strong></span>',
		title: '<span style="font-weight: normal; color: white">International</span> <span style="color: white">Symposium</span> <span style="font-weight: normal; color: white">on</span><br><span style="color: white">Joint Communications & Sensing</span>',
		dates: '<span style="color: white">January 28 - 30, 2025<br>In Finland & Streaming Worldwide</span>',
		message: [
			// { value: "Registration still available!" },

		],
		buttons: [

			// {
			// 	text: "Register Now",
			// 	till: "2023-03-07T13:00:00",
			// 	icon: "fa fa-play",
			// 	target: "#registration"
			// },
			// {
			 // 	text: "Become a Sponsor",
			// 	icon: "fa fa-file-pdf",
			 // 	target: "JC&S2025_PatronageFlyer.pdf"
			// },

			{
			 	text: "Newsletter Signup",
			 	icon: "fas fa-envelope", // FA Icon
			 	target: "https://ea.sendcockpit.com/_s.php?&fid=53691&fpw=d5ab1e24ece6c8adb8403336b0007aee",

			},
				{
			 	text: "Stay Tuned",
			 	icon: "fab fa-twitter", // FA Icon
			 	target: "https://twitter.com/5g_lab",

			},
			//{
			//	text: "Add to Calendar",
			//	icon: "far fa-calendar-alt",
			//	target: "jcns-2024.ics"
			//},
			{
				text: "Become a Patron",
				icon: "far fa-file-alt",
				target: "JC&S2025_PatronageFlyer.pdf"
			},
			// {
			// 	text: "6G Summit",
			 //	icon: "fa fa-star", // FA Icon
			// 	target: "http://5gsummit.org/dresden/"
		   	// },

			// {
			// 	text: "Video Guidlines",
			// 	icon: "fa fa-video",
			// 	till: "2023-03-07T10:00:00",
			// 	target: "/video-guidelines"
			// },
			//  {
			//  	text: "Proceedings",
			//  	icon: "fas fa-file-alt", // FA Icon
			//  	from: "2023-03-07T17:00:00",
			//  	target: "#proceedings"
			//  },
			//  {
			//  	text: "Photos",
			//  	icon: "fas fa-camera", // FA Icon
			//  	target: "https://5glab.de/impressions-of-jcs-2024/"
			//  },
			{
				text: "JC&S 2024",
				icon: "fas fa-history", // FA Icon
				target: "/legacy/2024",
			},



		]
	},
	sections: [
		{
			name: "about",
			background: "light",
			content: [
				// {
				// 	type: "html",
				// 	till: "2023-03-05T08:30:00",
				// 	value: `
				// 		<div style="font-size: 1.2em">
				// 		<p><strong>We'll be live in</strong></p>
				// 		<p><countdown to="2023-03-05T09:00:00"></countdown>.</p>
				// 		</div>
				// 	`
				// },

				// {
				// 	type: "html",
				// 	from: "2023-03-05T08:30:00",
				// 	till: "2023-03-05T18:00:00",
				// 	value: `
				// 		<div style="font-size: 1.2em">
				// 		<p><strong> >> <a href="${liveStreamUrl}">Watch the live stream!</a> <<</strong>
				// 		<br> (via ${liveStreamProvider} - <a href="#registration">registration required</a>)</p>
				// 		</div>
				// 	`
				// },

				// {
				// 	type: "html",
				// 	from: "2023-03-05T18:00:00",
				// 	till: "2023-03-06T08:30:00",
				// 	value: `
				// 	<div style="font-size: 1.2em">
				// 	<strong><p><a href="#registration">Register NOW</a> for day 2!</p></strong>
				// 	<p style="margin-top: 2em"><strong>The event continues in</strong></p>
				// 		<p><countdown to="2023-03-06T09:00:00"></countdown>.</p>
				// 	</div>
				// 	`
				// },
				// {
				// 	type: "html",
				// 	from: "2023-03-07T17:00:00",
				// 	value: `
				// 		<div style="font-size: 1.2em">
				// 		<p><strong>Recordings and slides are available in the <a href="#program">Program Section</a>.</p>
				// 		</div>
				// 	`
				// },

				// {
				// 	type: "html",
				// 	from: "2023-03-07T17:00:00",
				// 	value: `
				// 		<div style="border: 1px solid gray; margin-bottom: 2em; padding: 0 1em 1em 1em;">
				// 			<h2>Get the Proceedings</h2>
				// 			<a style="position: absolute; top: -8em;" id="proceedings"/>
				// 			<unlock v-if="!g.unlocked"/>
				// 			<div style="font-size: 1.4em" v-if="g.unlocked">
				// 				<a href="https://datashare.tu-dresden.de/s/ZFLbosniFz7naeR">Conference Proceedings</a>
				// 			</div>
				// 		</div>
				// 	`

				// },



				{
					type: "html",
					value: `
					<p>
					The integration of communication and radio sensing in the same spectrum is likely to be one of the key features in B5G wireless systems. A joint design of both services will improve the efficiency of spectrum usage, and will offer the opportunity of providing radar as a service, just like communications today. Recent advances in mmWave communications, massive MIMO and machine learning, among others, are making this vision possible, but many technical challenges still remain, from hardware up to the application layer. Therefore, Joint Communications and Sensing (JC&S) is the subject of rapidly growing interest in the research community, and this symposium will focus on the different system and implementation aspects of this promising new approach, bringing together academic and industrial experts from both the radar and communications research communities.
					</p>

					<p>
					We look forward to welcoming you in Finland in 2025. For those unable to attend, all sessions will also be streamed online and recordings will be available after the event.
					</p>
							`
				},

				{
					type: "html",
					value: "<cosponsors></cosponsors>",
				},
				{
					type: "html",
					value: `

						<sponsors json="data/sponsors.json" pictures="data/sponsors/" :exclude="['Exhibition']"></sponsors>
						`
				},
				  {
				 	type: "html",
				 	value: `
				  	<p style="margin-top: 2em; font-weight: bold;">
					Interested in becoming a patron? Check out our 
					<a href="JC&S2025_PatronageFlyer.pdf">Patronage Brochure <i class="fa fa-file-pdf"></i></a>.
					<br> To arrange your individual patronage package, please contact 
   					 <a href="mailto:nicole.flechs@5glab.de">Nicole Flechs</a>.
					</p>
				  	`
				  },



			],
		},
		{
			name: "speakers",
			title: "Speakers & Panelists",
			background: "dark",
			content: [
				{
					type: "component:speakers"
				}
			]
		},





		 {
		  	name: "program",
		  	title: "Program",
		  	background: "light",
		  	content: [
				{
					type: "html",
					value: `
					<p>To be announced.</p>`
				}

		 		// 	<p>The program is still subject to changes.</p>
		 		// 	<p>All registered attendees are welcome to join the tutorials (registered authors without additional costs).</p>
		 		// 	`
		 		// },

		 		// {
		 		// 		type:"html",
		 		// 		value:`
		 		// 		<h3>Display Times</h3>
		 		// 		<div>
				// 			<p v-if="!l.showLocalTimes">All times below are Central European Time (CET).</p>
				// 			<p v-if="l.showLocalTimes">All times below are converted to your device's time zone.</p>
		 		// 		</div>
		 		// 		<div style="margin-bottom: 3em;"><label class="check-label"><input type="checkbox" v-model="l.showLocalTimes">Show my local times</label></div>

		 		// 		<h2>Tuesday, March 19 (Tutorials)</h2>
				// 	`
		 		// },



		 		// 	{
		 		// 		type: "html",
		 		// 		value: `<program :program="g.programdata[0]" :firstcolwidth="10"></program>`
		 		// 	},
				// 	{
				// 		type:"html",
		 		// 		value:`<h2>Wednesday, March 20</h2>`
		 		// 	},
		 		// 	{
		 		// 		type: "html",
		 		// 		value: `<program :program="g.programdata[1]" :firstcolwidth="10"></program>`
		 		// 	},
				// 	{
				// 		type:"html",
		 		// 		value:`<h2>Thursday, March 21</h2>`
		 		// 	},
		 		// 	{
		 		// 		type: "html",
				// 		value: `<program :program="g.programdata[2]" :firstcolwidth="10"></program>`
		 		// 	},


		 		]
		 },

		{
			name: "venue",
			title: "Venue",
			background: "venue",
			content: [
				{
					type: "html",
					value: `
						<div style="font-size: 1.3em">
						<p><strong>Location: Oulu, Finland</strong></p>
						<p><strong>Venue: <a href="https://lasaretti.com/" target="_blank">Hotel Lasaretti Oulu</a--> </strong> </p>
						<p><strong>Accommodation: Flyer will be published soon<!--a href="https://www.visitleuven.be/en/accommodation-summary" target="_blank">book a hotel</a--> </strong> </p>
						</div>
					`
				}
			]
		},

		{
			name: "registration",
			title: "Registration",

			content: [

				{
					type: "html",
					value: `<p>To be announced.</p>`

				},
				//  {
				// 	type: "html",
				//  	value: `
				//  		<p style="margin-top: -0em; font-size: 1.5em;">Registration is available <a style="font-weight: bold" href="https://www.eventbrite.com/e/4th-ieee-symposium-on-joint-communications-sensing-tickets-803800788797" target="_blank">&gt;&gt;&nbsp;here&nbsp;&lt;&lt;</a>&nbsp;.</p>
				//  		<p><a href="JCNS24-Terms_and_Conditions.pdf">Terms and Conditions</a> apply.</p>
				//  	`
				//  },
				//  {
				//  	type: "html",
				//  	value: `<h2>Prices:</h2>`
				//  },
				//  {
				//  	type: "html",
				//  	value: `
				//  	<h3 style="margin-top: 0em">Physical Attendance</h3>
				//  	<label class="check-label"><input type="checkbox" v-model="l.includeVat">Include VAT in prices</label>
				// 	<div style="margin-bottom: -1em"></div>
				//  	`
				//  },
				//  {
				//  	type: "html",
				//  	value: `
				//  		<priceTable style="font-size: 1.2em"
				//  			:firstcolwidth="13.5"
				//  			:pricecategories="['Non IEEE Member','IEEE Member']"
				//  			:registrationtypes="['Author','Attendee','Attendee (Student)','Tutorial Day Only','Tutorial Day Only (Student)','Dinner Only']"
				//  			:pricedata="[[900, 850], [600, 575], [350, 325], [145, 125], [95, 85],[120,'-','-']]"
				// 			:vat="l.includeVat? 21 : 0"
				//  			:benefits="['Tuesday (Tutorials)','Welcome Reception','Wednesday', 'Social Dinner','Thursday']"
				//  			:benefitsdata="[[1,1,1,1,1],[0,1,1,1,1],[0,1,1,1,1],[1,1,0,0,0],[1,1,0,0,0],[0,0,0,1,0]]"
				//  		/>
				//  	`
				//  },

				//  {
				//  	type: "html",
				//  	value: `
				//  	<p><strong>Late Fee</strong> (from February 27, 2023): 50 EUR (+ taxes) surcharge.</p>
				// 	<p><strong>Last Minute Fee</strong> (from March 15, 2023): another 50 EUR on the late fee (+ taxes).</p>
				//  	<p>Prices <span v-show="!l.includeVat">excluding 21% VAT that apply additionally</span><span v-show="l.includeVat">including 21% VAT</span>.</p>
				//  	<p>Each paper must be covered by one author registration.</p>
				//  	`
				//  },
				//  {
				//  	type: "html",
				//  	value: `
				//  	<h3 style="margin-top: 2em">Virtual Attendance</h3>
				//  	<label class="check-label"><input type="checkbox" v-model="l.includeVat">Include VAT in prices</label>
				//  	<div style="margin-bottom: -1em"></div>
				//  	`
				//  },

				//  {
				//  	type: "html",
				//  	value: `
				//  		<priceTable style="font-size: 1.2em"
				//  			:firstcolwidth="13.5"
				//  			:pricecategories="['Non IEEE Member','IEEE Member']"
				//  			:registrationtypes="['Author','Attendee','Attendee (Student)','Tutorial Day Only','Tutorial Day Only (Student)']"
				//  			:pricedata="[[450, 400], [225, 200], [105, 90], [90, 75], [60, 50]]"
				//  			:vat="l.includeVat? 21 : 0"
				//  			:benefits="['Tuesday (Tutorials)','Welcome Reception','Wednesday', 'Social Dinner','Thursday']"
				// 			:benefitsdata="[[1,0,1,0,1],[1,0,1,0,1],[1,0,1,0,1],[1,0,0,0,0],[1,0,0,0,0]]"
				//  		/>
				//  	`
				//  },

				//  {
				//  	type: "html",
				//  	value: `
				// 	<p><strong>Late Fee</strong> (from February 27, 2023): 25 EUR (+ taxes) surcharge.</p>
				// 	<p><strong>Last Minute Fee</strong> (from March 15, 2023): another 25 EUR on the late fee (+ taxes).</p>
				//  	<p>Prices <span v-show="!l.includeVat">excluding 21% VAT that apply additionally</span><span v-show="l.includeVat">including 21% VAT</span>.</p>
				//  	<p>Each paper must be covered by one author registration.</p>
				//  	`
				//  }
			]
		},
		 {
		 	name: "cft",
		 	background: "dark",
		 	title: "Call for Tutorials/ Short Courses/ Half-Day Workshops",
		 	content: [
		 		{
		 			type: "html",
		 			value: `
		 			 		<p style="margin-top: -0em; font-size: 1.5em;">Download the Call for Tutorials <a style="font-weight: bold" href="CallforTutorialsWorkshop_2025.pdf">here</a>.</p>
		 					 <p style="margin-top: -0em; font-size: 1.5em;">Deadline: October 1, 2024 </p>						`
		 		},
		 	]
		 },

		 {
		 	name: "cfp",
		 	background: "white",
		 	title: "Call for Papers",
		 	content: [
		 		{
		 			type: "html",
		 			value: `
		 			 		<p style="margin-top: -0em; font-size: 1.5em;">Download the Call for Papers <a style="font-weight: bold" href="Call_for_Papers_JCnS2025_Extension.pdf">here</a>.</p>
		 					 <p style="margin-top: -0em; font-size: 1.5em;">To upload your paper, please visit our <a href="https://edas.info/N32907">EDAS</a> page!</p>
							 					
							`
		 		},
		 	]
		 },
		{
			name: "deadlines",
			background: "dark",
			title: "Deadlines",
			content: [
				  {
				  	type: "html",
				  	value: `



				  	<!--a class="btn btn-outline" style="border-color: white; margin: 2em 0" href="" @click.prevent="showCfP()">View Call for Papers</a-->
		 		  	<!--br-->
		 		  	<!--a class="btn btn-outline" href="" @click.prevent="showCRInstructions()">Instructions for Final Manuscript</a-->


				  	<div style="display: flex; align-items: center; justify-content: center">
				  	<div style="font-size: 1.4em; margin-bottom: 1em;line-height: 1.8em; border: 0px solid white; border-radius: 4px; width: fit-content; padding: 1em;">
				  		<div style="opacity: 1;">
				  			<strong>Paper Submission:</strong>

				  			<span style="display: inline-block; margin-left: 1em;">
				 					<strike> September 13, 2024 </strike> <strong> October 5, 2024 </strong> 
				  			</span>
				  		</div>
				  		<div style="opacity: 1">
				  			<strong>Acceptance Notification:</strong>
				  			<!--span style=" opacity: 0.6; display: inline-block; margin-left: 1em;  text-decoration: line-through; margin-right: 0.5em;">Dec 2022</span-->
				  			<span style="display: inline-block; margin-left: 0.5em;">Mid of November, 2024</span>
				  		</div>
				  		<div style="opacity: 1">
				  			<strong>Final Paper:</strong>
				  			<span style="display: inline-block; margin-left: 1em;">
				  				To be announced
				  			</span>
				  		</div>
				  		<div style="opacity: 1">
				  			<strong>Video Submission: </strong><span style="display: inline-block; position: relative; bottom: 0.2em;">*</span>
				  			<!--span style=" opacity: 0.6; display: inline-block; margin-left: 1em;  text-decoration: line-through; margin-right: 0.5em;">Jan 2023</span-->
				  			<span style="display: inline-block; margin-left: 0.5em;">To be announced</span>

				  		</div>

				  	</div>
				  	</div>
				  	<!--a href="video-guidelines" style="font-size: 1.8em; font-weight: bold;">>> Video Production Guidelines <<</a-->
				  	<p>All accepted and presented publications will be submitted for inclusion into IEEE Xplore subject to meeting IEEE Xplore's scope and quality requirements.</p>
					<p style="margin-top: 2em;">* For remote presentations only.</p>

				  	`

				  },
				// <a class="btn btn-outline-dark" href="jcns-cr-instructions.pdf">Instructions for Final Manuscript</a>

				//{
				//	type: "html",
				//	value: `
				//	<p>To be announced.</p>
				//	`
				// }
			]
		},


		{
			name: "committee",
			title: "Committee",
			background: "white",
			content: [
				{
					type: "component:organizers"
				}
			]
		},

	],

	cfp: cfp,
	cameraReadyInstructions: `
		<p>If you have received a paper acceptance email from the TPC co-chairs, please use these author instructions to prepare your final camera-ready paper.</p>
		<p>Formatting checks are enforced in the submission phase for final manuscripts. Please allow extra time to fix any formatting errors and use PDF-eXpress to generate a compliant file.</p>
		<h3>Copyright</h3>
		<p>Authors must sign their copyright form via IEEE eCopyright service. Please login to your EDAS account and look for your accepted paper(s) under your EDAS dashboard, then click to add a “copyright form”. You will be then redirected to an EDAS webpage in which you should click on the button “IEEE Copyright Submission”. Next, you will be redirected to IEEE eCF website where you just need to follow the wizard to complete, sign and submit your copyright form online.</p>
		<p>The appropriate copyright clearance code notice is to appear on the bottom of the first page of each paper per the guidelines set forth in the Cataloging / Copyright Instructions for an IEEE Conference Proceeding. Detailed instructions can be found at: <a href="http://www.ieee.org/publications_standards/publications/rights/index.html" target="_blank">http://www.ieee.org/publications_standards/publications/rights/index.html</a></p>
		<ul>
		 <li>For papers in which all authors are employed by the US government, the copyright notice is: <strong style="color:red">U.S. Government work not protected by U.S. copyright</strong></li>
		 <li>For papers in which all authors are employed by a Crown government (UK, Canada, and Australia), the copyright notice is: <strong style="color:red">978-1-6654-0579-9/22/$31.00 ©2022 Crown</strong></li>
		 <li>For papers in which all authors are employed by the European Union, the copyright notice is: <strong style="color:red">978-1-6654-0579-9/22/$31.00 ©2022 European Union</strong></li>
		 <li>For all other papers the copyright notice is: <strong style="color:red">978-1-6654-0579-9/22/$31.00 ©2022 IEEE</strong></li>
		</ul>
		<p><strong>Latex users</strong>can add following lines just before <code>begin{document}</code> for the copyright notice to show up (shown below as an example for the fourth case above):</p>
<pre>\\IEEEoverridecommandlockouts
\\IEEEpubid{\\makebox[\\columnwidth]{978-1-6654-0579-9/22/\\$31.00~\\copyright{}2022 IEEE \\hfill} \\hspace{\\columnsep}\\makebox[\\columnwidth]{ }}</pre>
		<p>MS Word users can use <i>Insert</i> → <i>Text Box</i>, to insert the appropriate copyright notice in the texbox, and place the box (without border) at the bottom left of the first page.</p>
		<h3>IEEE PDF eXpress</h3>
		<ul>
		 <li>Go to <a href="http://ieee-pdf-express.org/" target="_blank">IEEE PDF eXpress Website</a>.</li>
		 <li>Create an IEEE PDF eXpress account (use conference ID: <strong style="color: red">54387X</strong>)</li>
		 <li>Use ‘new user’ if you haven’t used this site before.</li>
		</ul>
		<p>Use IEEE PDF eXpress to check if your PDF file is Xplore-compliant, OR, else, you can use this site to convert your source files into an IEEE Xplore-compliant PDF file (e.g., for LaTeX users, create a zip file that includes dvi and your eps figure files all together, and then upload the zip file for the system to convert into a compliant PDF file; for MSWord users, upload the Word file and let it convert and return a compliant PDF file).</p>

		<h3>Camera-Ready Paper Upload to EDAS</h3>
		<ul>
		  <li>Log into <a href="https://edas.info/" target="_blank">EDAS</a> and upload the following at your paper entry page: Camera-ready copy of your paper that must be PDF eXpress compliant.</li>
		</ul>

		<p>In order to be published in the Proceedings of the 2nd IEEE Symposium on Joint Communications & Sensing and to be submitted for inclusion in IEEE Xplore®, an author of an accepted paper is required to register for the conference at the full rate and the paper must be presented at the conference.</p>

		<p><strong>One full author registration is valid for one paper</strong>.

		<p><strong>If you have any questions on creating and/or submitting the camera-ready paper, please contact the publication chairs or the TPC co-chairs:</strong></p>
		<ul>
		  <li><a href="mailto:Andre.Nollbarreto@barkhauseninstitut.org">André Noll Barreto</a>, Barkhausen Institut</li>
		  <li><a href="mailto:Andre.Bourdoux@imec.be">André Bourdoux</a>, imec, Belgium</li>
		  <li><a href="mailto:liuf6@sustech.edu.cn">Fan Liu</a>, SUS Tech, China</li>
		</ul>
	`

};
